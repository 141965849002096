/*
|--------------------------------------------------------------------------
| Arrays composables utilities
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods used in
| the different components for the manipulation of the Arrays.
*/

export default function useObjectsUtils() {


    // Function to sort an Array of Object by passing key property and type
    // of wished property to sort on
    function sortArrayForTables ( arr, byKey, type, sort ) {

        if ( sort.col === byKey ) {
            sort.asc = !sort.asc
        } else {
            sort.asc = true
            sort.col = byKey
        }


        if ( type === 'number' ) {
        // If Array has to be sorted on property with type of 'number'
            arr.sort( (a, b) => {
                if ( sort.asc === true ) {
                    return a[byKey] - b[byKey]
                } else {
                    return b[byKey] - a[byKey]
                }

            })

        } else if ( type === 'string' ) {
        // If Array has to be sorted on property with type of 'string'
            arr.sort( (a, b) => {
                if ( sort.asc === true ) {
                    if ( a[byKey].toLowerCase() < b[byKey].toLowerCase() ) {
                        return -1;
                    }
                    if ( a[byKey].toLowerCase() > b[byKey].toLowerCase() ) {
                        return 1;
                    }
                    return 0;
                } else {
                    if ( a[byKey].toLowerCase() > b[byKey].toLowerCase() ) {
                        return -1;
                    }
                    if ( a[byKey].toLowerCase() < b[byKey].toLowerCase() ) {
                        return 1;
                    }
                    return 0;
                }

            })

        } else {
        // Anything else
            arr.sort( (a, b) => {
                if ( sort.asc === true ) {
                    if ( a[byKey] < b[byKey] ) {
                        return -1;
                    }
                    if ( a[byKey] > b[byKey] ) {
                        return 1;
                    }
                    return 0;
                } else {
                    if ( a[byKey] > b[byKey] ) {
                        return -1;
                    }
                    if ( a[byKey] < b[byKey] ) {
                        return 1;
                    }
                    return 0;
                }

            })
        }

    }


    return {
        sortArrayForTables,
    }
}
