<template>
    <div
        v-if="exceptionRised"
        class="alert alert-danger"
    >
        <p>
            <strong>{{ exceptionMessage }}</strong>
        </p>

        <span v-for="(info, index) in exception.errorInfo" v-bind:key="index">
            [{{ index }}] : {{ info }} <br/>
        </span>
    </div>
</template>

<script>

import { computed } from 'vue';

export default {

    name: 'exceptionMessage',

    props: {

        exception: {
            type: Object,
        },
        exceptionMessage: {
            type: String,
        },

    },

    setup(props) {

        const exceptionRised = computed(() => {
            return Object.keys(props.exception).length !== 0
        })

        return {
            exceptionRised
        }
    },

}
</script>
