/*
|--------------------------------------------------------------------------
| CRUD operations to manage resources with the backend - composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different composable files with the CRUD operations
| to manage the resources with the backend.
*/

// Import Vue.js Framework functions
//import { ref } from 'vue'
//import { toRefs } from 'vue'
//import { reactive } from 'vue'

// Import librairies
import axios from 'axios'

// Export function with contains the exosed properties and methods
export default function useCrudComp() {

/*
    const state = reactive({
        resources: null,
    })
*/



    function getAllResources(data) {
        return axios({
            method: 'get',
            url: data.url,
        })
    /*
        .then((res) => {
            state.resources = res.data.data
        })
    */
    }

    function getResourcesWithPagination(data) {
        return axios({
            method: 'get',
            url: data.url,
        })
    }


    function getPdf(data) {

/*
        return axios
            .get(`${process.env.VUE_APP_API_URL}/` + data.url)
            .then(() => {
                console.log('ok')
            })
*/


        return axios({
            method: 'get',
            url: data.url,
            responseType: 'blob', // important
        })
        .then((response) => {

            //console.log('useCrud : then response')
            //console.log(response)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            //link.setAttribute('download', 'file.pdf');
            link.setAttribute('download', data.fileName);
            document.body.appendChild(link);
            link.click();

            //console.log('useCrud : pdf done')

        });



    }

    function searchResources(data) {
        return axios({
            method: 'get',
            url: data.url,
        })
    }

    function getResourcesFromDateInterval(data) {
        return axios({
            method: 'post',
            url: data.url,
            data: data.resource
        })
    }

    function getFromArrayId(data) {
        return axios({
            method: 'post',
            url: data.url,
            data: data.resource
        })
    }

    function storeResource(data) {

        return axios({
            method: 'post',
            url: data.url,
            data: data.resource
        })
    /*
        .then((res) => {
            state.resources.push(res.data.resource)
        })
    */
    }

    function showResource(data) {
        return axios({
            method: 'get',
            url: data.url,
            data: data.id
        })
    }

    function updateResource(data) {
        return axios({
            method: 'put',
            url: data.url,
            data: data.resource
        })
    }

    function deleteResource(data) {
        return axios({
            method: 'delete',
            url: data.url,
            data: data.id
        })
    }

    function deleteAllResourcesFromGroupId(data) {
        return axios({
            method: 'delete',
            url: data.url,
            data: data.id
        })
    }

    function deleteResourcesFromNowFromGroupId(data) {
        return axios({
            method: 'delete',
            url: data.url,
            data: data.id
        })
    }

    return {
        //...toRefs(state),
        //resources,
        getAllResources,
        getResourcesWithPagination,
        getPdf,
        searchResources,
        getResourcesFromDateInterval,
        getFromArrayId,
        storeResource,
        showResource,
        updateResource,
        deleteResource,
        deleteAllResourcesFromGroupId,
        deleteResourcesFromNowFromGroupId
    }
}
