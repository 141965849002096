/*
|--------------------------------------------------------------------------
| Objects composables utilities
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods used in
| the different components for the manipulation of the Objects.
*/

export default function useObjectsUtils() {

    // Clone an Object in another one
    function cloneObject (obj) {
        // using native JSON functions removes reactivity
        // so we can clone an object without mutating the original source
        //console.log(JSON.stringify(obj));
        return JSON.parse(JSON.stringify(obj));
    }

    function deepCloneObject (obj) {
        let newObj = {}
        newObj = Object.assign({}, obj)
        return newObj
    }

    // Set all Object properties to given value
    function setAll(obj, val) {
        Object.keys(obj).forEach(function(index) {
            obj[index] = val
        });
    }

    // Set all Object properties to null
    function setNull(obj) {
        setAll(obj, null);
    }

    return {
        cloneObject,
        deepCloneObject,
        setAll,
        setNull
    }

}
