/*
|--------------------------------------------------------------------------
| Authentication Forms utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Authentication
| of the application.
*/

// import Vue.js features
import { reactive } from 'vue'
// import { computed } from 'vue'

// import own utility function library
import useObjectsUtils from './useObjectsUtils.js'

// export the global function reused in the components
export default function useFormUtilsComp() {

    // define variable with the needed utils functions
    const objUtils = useObjectsUtils();

    // define the regular expression to test and validate email form
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    // define the reactive variables
    const state = reactive({
        form: {
            errors: {},
            exception: {},
            exceptionMessage: null,
            response: {
                message: null,
                alert: null,
            }
        },
    })

    // Check if field is empty
    function isFieldEmpty(content) {
        return (content == '') || (content == null)
    }

    // Check if email form input is correct according to regex
    function isEmailValid(email) {
        return regex.test(email)
    }

    // Check if password form input is empty
    function isPasswordEmpty(password) {
        return (password == '') || (password == null)
    }

    // Check if entered password and confirmed password match together
    function passwordsMatch(password, password_confirmation) {
        return (
            (
                password === password_confirmation
            ) && (
                password !== ''
            ) && (
                password !== null
            )
        )
    }

    // set the errors or exceptions received from backend in the state
    function useSetErrors(err) {

        if (err.errors !== undefined) {
            state.form.errors = err.errors
        }

        if (err.exception !== undefined) {
            state.form.exception = err.exception
            state.form.exceptionMessage = err.exceptionMessage
        }

        useMessages(err, 'alert-danger')
    }

    // set the message to display
    function useMessages(data, alert) {
        state.form.response.message = data.message
        state.form.response.alert = alert
    }

    // reset errors or exceptions
    function useResetErrors() {
        state.form.errors = {}
        state.form.exception = {}
        state.form.exceptionMessage = null
        objUtils.setNull(state.form.response)
    }

    // return the different information to be exposed to the components
    return {
        state,
        isFieldEmpty,
        isEmailValid,
        isPasswordEmpty,
        passwordsMatch,
        useSetErrors,
        useMessages,
        useResetErrors,
        regex
    }
}
