<template>


    <div class="modal fade" v-bind="{ id: idProps }" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">

            <div class="modal-content rounded-5 shadow">
                <div class="modal-header p-5 pb-4 border-bottom-0">
                    <!-- <h5 class="modal-title">Modal title</h5> -->

                    <h3 class="fw-bold mb-0">
                        <slot name="header">
                            Default title
                        </slot>
                    </h3>

                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="close"
                    ></button>
                </div>

                <div class="modal-body p-5 pt-0">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </div>


                <div class="modal-footer flex-nowrap p-0">
                    <slot name="footer"></slot>
                </div>

            </div>
        </div>
    </div>


</template>

<script>

export default {

    name: 'Modal',

    emits: ['close'],

    props: {
        idProps: {
            type: String,
            required: true
        },
    },

}
</script>
