<template>

    <div
        v-if="data.message"
        class="alert"
        v-bind:class="data.alert"
        role="alert"
    >
        {{ data.message }}
    </div>

</template>

<script>

export default {

    name: 'AltertMessage',

    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
    },

}
</script>
