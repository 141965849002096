<template>
    <div class="text-danger text-sm" v-if="errors">
        <div v-for="error in errors" v-bind:key="error">
            {{ error }}
        </div>
    </div>
</template>

<script>

export default {

    name: 'validationErrors',

    props: {
        errors: {
            type: Object,
        },
    },

}
</script>
