/*
|--------------------------------------------------------------------------
| Date and Time composables utilities
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods used in
| the different components for the manipulation of the Date and Time.
*/

import { reactive } from 'vue'
import { getCurrentInstance } from 'vue'

import { useI18n } from 'vue-i18n'

export default function useDateTimeUtilsComp() {

    const internalInstance = reactive( getCurrentInstance() )

    const { t } = useI18n({ useScope: 'global' })


    // Return formated date to display
    function formatedDate(date, lang, format) {

        if (date) {
            let myFormatedDate = internalInstance.appContext.config.globalProperties
                                    .$moment(date).locale(lang).format(format)
            return myFormatedDate
        } else {
            return null
        }
    }

    // Return formated date to display
    function databaseFormatedDate(date, format) {

        if (date) {
            let myFormatedDate = internalInstance.appContext.config.globalProperties
                                    .$moment(date).format(format)
            return myFormatedDate
        } else {
            return null
        }
    }


    function getWeekDay(date, index) {
        let day = date.getDay()
        let diff

        if ( index < 6 ) {
            diff = date.getDate() - day + (day == 0 ? -6 : 1) + index // adjust when day is sunday
        } else {
            diff = date.getDate() + 7 - (day == 0 ? 7 : day) // adjust when day is sunday
        }


        let weekDay = new Date(date)
        //let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

        //console.log(event.toLocaleDateString('de-DE', options));

        return new Date(weekDay.setDate(diff))
    }



    function getMonday(date) {

        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
        let monday = new Date(date)

        return new Date(monday.setDate(diff))
    }


    function getTuesday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 1  // adjust when day is sunday
        let tuesday = new Date(date)

        return new Date(tuesday.setDate(diff))
    }

    function getWednesday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 2  // adjust when day is sunday
        let wednesday = new Date(date)

        return new Date(wednesday.setDate(diff))
    }

    function getThursday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 3  // adjust when day is sunday
        let thursday = new Date(date)

        return new Date(thursday.setDate(diff))
    }

    function getFriday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 4  // adjust when day is sunday
        let friday = new Date(date)

        return new Date(friday.setDate(diff))
    }

    function getSaturday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 5  // adjust when day is sunday
        let saturday = new Date(date)

        return new Date(saturday.setDate(diff))
    }


    function getSunday(date) {
        let day = date.getDay()
        let diff = date.getDate() + 7 - (day == 0 ? 7 : day) // adjust when day is sunday
        let sunday = new Date(date)

        return new Date(sunday.setDate(diff))
    }

    function getWeekNumber(date) {
        let firstJanuary = new Date(date.getFullYear(),0,1)
        let numberOfDays = Math.floor((date - firstJanuary) / (24 * 60 * 60 * 1000))
        let result = Math.ceil(( date.getDay() + 1 + numberOfDays) / 7)
        return result
    }

    function getMonthNumber(date) {
        let month = date.getMonth()
        return month
    }


    function checkWeekDay(date, day) {
        let d = new Date(date)

        if ( (day == 7) && (d.getDay() == 0) ) {
            return 1
        } else {
            if ( d.getDay() == day ) {
                return 1
            } else {
                return 0
            }
        }
    }

    function addOneWeek(date) {
        let diff = date.getDate() + 7
        return new Date(date.setDate(diff))
    }

    function substractOneWeek(date) {
        let diff = date.getDate() - 7
        return new Date(date.setDate(diff))
    }

    function addOneDay(date) {
        let diff = date.getDate() + 1
        return new Date(date.setDate(diff))
    }

    function substractOneDay(date) {
        let diff = date.getDate() - 1
        return new Date(date.setDate(diff))
    }

    function addWeeks(date, n) {
        let nbWeeks = n * 7
        let diff = date.getDate() + nbWeeks
        return new Date(date.setDate(diff))
    }

    function addDays(date, n) {
        let nbDays = n
        let diff = date.getDate() + nbDays
        return new Date(date.setDate(diff))
    }


    function addMonths(date, n) {
        return new Date(date.setMonth(date.getMonth()+n))
    }

    function substractMonths(date, n) {
        return new Date(date.setMonth(date.getMonth()-n))
    }

    function getFirstDayOfTheMonth(date) {
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        return firstDay
    }

    function getLastDayOfTheMonth(date) {
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        return lastDay
    }

    function showDate(date) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let fullYear = date.getFullYear()
        let dateToShow = day + '.' + month + '.' + fullYear
        return dateToShow
    }

    function formatIsoDate(date) {


        let tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime = new Date((date - tzoffset)).toISOString().slice(0, -1); // => '2015-01-26T06:40:36.181'

/*
        console.log('date')
        console.log(localISOTime.replace(/T.*$/, ''))  // => '2015-01-26T06:40:36.181'


        let test = new Date('Mon Oct 31 2022 00:30:00 GMT+0100')

        let tzoffset_test = new Date(test).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime_test = new Date((test - tzoffset_test)).toISOString().slice(0, -1);

        console.log('---------------------------')
        console.log('test')
        console.log(localISOTime_test.replace(/T.*$/, ''))  // => '2015-01-26T06:40:36.181'

        console.log('===============================')

*/

        //return date.toISOString().replace(/T.*$/, '')
        return localISOTime.replace(/T.*$/, '')
    }

    /**
     * Get the list of the weekdays short to select in the checkbox options
     *
     * @return {Array} List of weekdsays short
     */
    function getWeekdaysShortOptionsList() {
        let list = [
            { text: t('form.options.weekdaysShort.monday'), value: 1, },
            { text: t('form.options.weekdaysShort.tuesday'), value: 2, },
            { text: t('form.options.weekdaysShort.wednesday'), value: 3, },
            { text: t('form.options.weekdaysShort.thursday'), value: 4, },
            { text: t('form.options.weekdaysShort.friday'), value: 5, },
            { text: t('form.options.weekdaysShort.saturday'), value: 6, },
            { text: t('form.options.weekdaysShort.sunday'), value: 0, },
        ]
        return list
    }



    return {
        formatedDate,
        getWeekDay,
        getMonday,
        getTuesday,
        getWednesday,
        getThursday,
        getFriday,
        getSaturday,
        getSunday,
        getWeekNumber,
        getMonthNumber,
        checkWeekDay,
        addOneWeek,
        substractOneWeek,
        addOneDay,
        substractOneDay,
        addDays,
        addWeeks,
        addMonths,
        substractMonths,
        getFirstDayOfTheMonth,
        getLastDayOfTheMonth,
        showDate,
        databaseFormatedDate,
        formatIsoDate,
        getWeekdaysShortOptionsList,
    }

}
