<template>
    <div
        class="bg-body text-dark border border-white d-flex flex-nowrap"
        @click="$emit('sort-event')"
        >
            <i
                v-bind:class="state.class.icon"
            ></i>
            <span v-bind:class="state.class.header">{{ titleName }}</span>
    </div>
</template>


<script>

import { reactive } from 'vue'
import { computed } from 'vue'

export default {

    name: 'TableHeaderItem',

    props: {
        titleName: {
            type: String,
            default: '',
        },
        sortArgs: {
            type: Object,
            default() {
                return {
                    col: '',
                    asc: true,
                }
            },
        },
        headerName: {
            type: String,
            default: '',
        },
    },

    setup(props) {

        const state = reactive({

            class: {
                icon: computed(() => {
                    // return dynamically class propreties
                    if ( props.sortArgs.col === props.headerName ) {
                        if ( props.sortArgs.asc === true ) {
                            return 'bi bi-caret-down-fill h-auto d-inline-block'
                        } else {
                            return 'bi bi-caret-up-fill h-auto d-inline-block'
                        }
                    } else {
                        return 'bi bi-caret-down h-auto d-inline-block'
                    }
                }),

                header: computed(() => {
                    // return dynamically class propreties
                    if ( props.sortArgs.col === props.headerName ) {
                        return 'fw-bold text-primary'
                    } else {
                        return 'fw-bold'
                    }

                }),
            },

        })


        return {
            state,
        }

    },

}
</script>
