/*
|--------------------------------------------------------------------------
| Errors utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components to manage the errors in the forms
| of the application.
*/

// import Vue.js features
import { reactive } from 'vue'
//import { toRefs } from 'vue'

// import own utility function library
import useObjectsUtils from './useObjectsUtils.js'


export default function useErrorsComp() {

    // define variable with the needed utils functions
    const objUtils = useObjectsUtils();

    const state = reactive({
        errors: {},
        exception: {},
        exceptionTitle: null,
        exceptionMessage: null,
        response: {
            message: null,
            alert: null,
        }
    })


    // set the errors or exceptions received from backend in the state
    function useSetErrors(err) {

        if (err.errors !== undefined) {
            state.errors = err.errors
        }

        if (err.exception !== undefined) {
            state.exception = err.exception
            state.exceptionTitle = err.exceptionTitle
            state.exceptionMessage = err.exceptionMessage
        }

        useMessages(err, 'alert-danger')
    }

    // set the message to display
    function useMessages(data, alert) {
        state.response.message = data.message
        state.response.alert = alert
    }

    // reset errors or exceptions
    function useResetErrors() {
        state.errors = {}
        state.exception = {}
        state.exceptionTitle = null
        state.exceptionMessage = null
        objUtils.setNull(state.response)
    }


    return {
        // ...toRefs(state),
        state,
        useSetErrors,
        useMessages,
        useResetErrors
    }


}
